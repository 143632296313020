import API from 'o365.modules.data.api.ts';
import Mustache from 'o365.lib.Mustache.js';

const PersonCache = new Map();

export default{
    name:"OPersonHover",
    popover: null,
    mounted(el, binding) {
        binding.popover = new bootstrap.Popover(el, {
            template: `<div class="popover-personinfo" role="tooltip">
                <h3 class="popover-header p-2"></h3>
                <div class="popover-body p-2"></div>
            </div>`,
            title: 'Loading...',
            content: 'Loading...',
            trigger: 'hover click',
            html: true,
            placement: "bottom"
        });

        el.setAttribute("data-personhover-value", binding.value);
        el.setAttribute('data-bs-container','body');
        el.addEventListener('show.bs.popover', async function () {
            let value = this.getAttribute("data-personhover-value");
            let data = PersonCache.get(value);
            if(data == undefined){
                data = await loadData(value);
                PersonCache.set(value, data);
            }
            if(data.length > 0){
                data = data[0];
            }
            let instance = bootstrap.Popover.getInstance(this);
            let title = getTitle({
                ID: data.ID,
                Name: data.Name,
                Represents: data.Represents
            });
            let content = getContent({
                Email: data.Email,
                MobileNo: data.MobileNo,
                PicturePrimKey: data.PicturePrimKey,
                PictureFileName: data.PictureFileName,
                DaysSinceLoggedIn: data.DaysSinceLoggedIn
            });
            instance._config.title = title;
            instance._config.content = content;
            let contentEl = instance.tip.querySelector(".popover-body");
            let titleEl = instance.tip.querySelector(".popover-header");
            contentEl.innerHTML = content;
            titleEl.innerHTML = title;
        });
    },
    updated(el, binding) { 
        el.setAttribute("data-personhover-value", binding.value);
    },
    unmounted(_, binding) {
        if(binding.popover) binding.popover.dispose();
    }
}

async function loadData(id){
    return await API.request({
        requestInfo: '/api/data/sviw_System_Persons',
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            "viewName": "sviw_System_Persons",
            "distinctRows": false,
            "skip": 0,
            "fields": [
                { name: "ID", type: "string" },
                { name: "Name", type: "string" },
                { name: "Email", type: "string" },
                { name: "Expired", type: "date" },
                { name: "MobileNo", type: "string" },
                { name: "Represents", type: "string" },
                { name: "DaysSinceLoggedIn", type: "number" },
                { name: "PicturePrimKey", type: "uniqueidentifier" },
                { name: "PictureFileName", type: "string" }
            ],
            "maxRecords": 1,
            "whereClause": `ID = ${id}`,
            "masterDetailString": null,
            "filterString": null,
            "operation": "retrieve"
        })
    });
}

function getTitle(data){
    return Mustache.render(`
        <div class="fw-bold"><small>{{Name}} (ID: {{ID}})</small></div>
        <small class="font-weight-normal">Represents: {{Represents}}</small>
    `, data);
}

function getContent(data){
    switch(data.DaysSinceLoggedIn) {
        case null:
            data.DaysSinceLoggedIn = '';
            break;
        case 0:
            data.DaysSinceLoggedIn = 'Last active: today';
            break;
        case 1:
            data.DaysSinceLoggedIn = `Last active: ${data.DaysSinceLoggedIn} ${"day ago"}`;
            break;    
        default:
            data.DaysSinceLoggedIn = `Last active: ${data.DaysSinceLoggedIn} ${"days ago"}`;
    }

    var imageString = data.PicturePrimKey ? `<img class="rounded" src="/api/file/view/stbv_System_PersonsImages/{{PicturePrimKey}}?mwidth=49">` : `<i class="bi bi-person-circle" style="font-size:50px;max-width:3.25rem;"
    ></i>`

    return Mustache.render(`
    <div class="row gx-5">
        <div class="col-2">
            ${imageString}
        </div>
        <div class="col-10">
            <div>
                <span>{{Email}}</span>
            </div>
            <div>
                <span>{{MobileNo}}</span>
            </div>
            <div>
                <small>{{DaysSinceLoggedIn}}</small>  
            </div>
        </div>
    </div>
    `, data);
}

